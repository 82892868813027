import 'slick-carousel'
import momemt from 'moment';


jQuery( document ).ready(function($) {



  $('.slider-music, .slider-videos').slick({

    slidesToShow: 1,

    slidesToScroll: 1,

    mobileFirst: true,

    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-caret-left"></i></button>',

    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-caret-right"></i></button>',

  })



  $('.slider-merch').slick({

    slidesToShow: 1,

    slidesToScroll: 1,

    mobileFirst: true,

    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-caret-left"></i></button>',

    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-caret-right"></i></button>',

    responsive: [

      {

        breakpoint: 992,

        settings: {

          slidesToShow: 3,

        }

      }

    ]

  });



  $('.form-open, .form-close').click(function(e){

    e.preventDefault()

    $('.popup').fadeToggle()

  })

  

  $('.filter').click(function(e){

    e.preventDefault()

    $('.form').hide()

  })

  $('.filter-es').click(function(e){

    e.preventDefault()

    $('.form-es').show()

  })

  $('.filter-en').click(function(e){

    e.preventDefault()

    $('.form-en').show()

  })



  $('.all-events').click(function(e){

    e.preventDefault()

    $('#events').toggleClass('all')

    if($(this).html() === 'show more dates'){

      $(this).html('show less dates')

    }else{

      $(this).html('show more dates')

    }

  })

  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Romeo Santos/events?app_id=45PRESS_EMILIA',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + (event.venue.region?event.venue.region:event.venue.country);
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'ddd MMM D' ) + '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-black">' + offer.type + '</a>';
        }
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });


});